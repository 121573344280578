// Only import in <Head> on Troika pages. Remove when everything is Fabric.
export const troikaStyles = (
    <>
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/core.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/ads.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/ad-gallery.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/ad-preview.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/ad-stream.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/banners.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/breadcrumbs.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/bottom-sheet.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/broadcast.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/button.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/checkbox-list.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/context-box.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/data-table.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/definition-list.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/expansion-panel.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/filters-overlay.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/flex-align.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/form-grid.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/form-std.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/grid.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/grid-cols-f-to-t.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/grid-cols-n-from-bp.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/icon.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/image-carousel.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/imageformat.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/image-frame.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/image-icon.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/input.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/input-toggle.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/lightbox-gallery.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/link.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/list.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/media.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/modal.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/pageholder.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/pagination.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/panel.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/save-ad.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/skeleton-screen.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/slider.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/status.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/step-indicator.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/suggestion-dropdown.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/toast.min.css" />
        <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/utilities.min.css" />
    </>
);
