import type { PropsWithChildren } from 'react';
import NextHead from 'next/head';

import { troikaStyles } from './TroikaStyles';

type HeadProps = {
    title: string;
    canonical: string;
    description: string;
    imageUrl?: string | undefined;
    troika?: boolean; // remove when Troika is gone
};

export const Head = ({ title, canonical, description, imageUrl, troika, children }: PropsWithChildren<HeadProps>) => (
    <NextHead>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=Edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="https://www.finn.no/favicon.ico" />
        <link rel="icon" href="https://www.finn.no/favicon-t-16x16.png" sizes="16x16" />
        <link rel="icon" href="https://www.finn.no/favicon-t-32x32.png" sizes="32x32" />
        <link rel="icon" href="https://www.finn.no/favicon-t-96x96.png" sizes="96x96" />
        <link rel="icon" href="https://www.finn.no/favicon-t-192x192.png" sizes="192x192" />
        <meta name="theme-color" content="#06befb" />
        <link rel="apple-touch-icon" href="https://www.finn.no/apple-touch-icon-180x180.png" />
        <meta name="msapplication-TileColor" content="#06befb" />
        <meta name="msapplication-TileImage" content="https://www.finn.no/mstile-144x144.png" />
        <meta name="msapplication-config" content="https://www.finn.no/browserconfig.xml" />
        <meta name="twitter:site" content="@FINN_no" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@FINN_no" />
        <link rel="manifest" href="https://www.finn.no/manifest.json" />

        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="FINN.no" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:locale" content="no_NO" />
        <meta property="og:image" content={imageUrl ?? 'https://www.finn.no/assets/finn-logo-large.png'} />
        <meta property="og:url" content={canonical} />

        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonical} />

        {children}

        {troika ? troikaStyles : <link rel="stylesheet" href="https://assets.finn.no/pkg/@fabric-ds/css/v1/fabric.min.css" />}
    </NextHead>
);

export const SimpleHead = ({ title, canonical, description, imageUrl, children }: PropsWithChildren<HeadProps>) => (
    <NextHead>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl ?? 'https://www.finn.no/assets/finn-logo-large.png'} />
        <meta property="og:url" content={canonical} />

        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonical} />

        {children}
    </NextHead>
);
