import { useEffect, useState } from 'react';

import { dismissBroadcast, filterDismissedBroadcasts, purgeOutdatedBroadcasts } from './broadcastState';

const messagesOrEmptyArray = (messages) => (Array.isArray(messages) ? messages : []);

interface BroadcastMessage {
    id: number;
    message: string;
    heading?: string | undefined;
}

export interface BroadcastProps {
    url?: string;
    messages?: BroadcastMessage[];
    className?: string | undefined;
    troika?: boolean;
}

export const Broadcast = (props: BroadcastProps) => {
    const [messages, setMessages] = useState(messagesOrEmptyArray(props.messages));

    useEffect(() => {
        const fetchBroadcast = async (url: string) => {
            try {
                const data = await fetch(url);
                const json = await data.json();

                setMessages(messagesOrEmptyArray(json));
            } catch (e) {
                setMessages([]);
            }
        };

        if (props.url) {
            fetchBroadcast(props.url);
        }
    }, [props.url]);

    purgeOutdatedBroadcasts();

    const dismissMessage = (id) => {
        dismissBroadcast(id);
        setMessages(messages.filter((message) => message.id !== id));
    };

    const renderTroika = (entry: BroadcastMessage) => (
        <section
            className={`broadcast ${props.className ?? ''}`}
            // biome-ignore lint/a11y/useSemanticElements: something about alert role
            role="alert"
            aria-atomic="false"
            data-testid="broadcast"
            key={`broadcast${entry.id}`}>
            <button
                type="button"
                className="button button--link button--small u-stone u-position-absolute u-top u-right u-pt8 u-pr8"
                aria-label="Skjul meldingen"
                onClick={() => dismissMessage(entry.id)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14">
                    <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2.5"
                        d="M12 12l6 6-6-6-6 6 6-6zm0 0L6 6l6 6 6-6-6 6z"
                    />
                </svg>
            </button>
            <div className="broadcast__content">
                {entry.heading?.trim() ? (
                    <h2 className="u-t3" data-testid="broadcast-title">
                        {entry.heading.trim()}
                    </h2>
                ) : null}
                <span data-testid="broadcast-text" dangerouslySetInnerHTML={{ __html: entry.message }} />
            </div>
        </section>
    );

    const renderFabric = (entry: BroadcastMessage) => (
        <section className="overflow-hidden w-full px-0 sm:px-16" aria-label="Varsel" data-testid="broadcast" key={`broadcast${entry.id}`}>
            <div className="toast flex group p-8 mt-16 rounded-8 border-2 w-full pointer-events-auto transition-all bg-yellow-50 border-yellow-200 text-yellow-800">
                <div className="flex-shrink-0 rounded-full w-16 h-16 m-8 bg-yellow-300">
                    <svg
                        role="img"
                        aria-label="Varsel"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 16 16"
                        className="transition-transform duration-200">
                        <path stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.5" d="M8 9V4" />
                        <circle cx="8" cy="11.8" r=".8" fill="currentColor" />
                    </svg>
                </div>
                {/* biome-ignore lint/a11y/useSemanticElements: something about alert role */}
                <div role="alert" className="self-center mr-8 py-4 last-child:mb-0">
                    <p id="toast-broadcast-toast-text" dangerouslySetInnerHTML={{ __html: entry.message }} />
                </div>
                <div>
                    <button
                        type="button"
                        className="button button--pill text-gray-500"
                        style={{ minWidth: 32, minHeight: 32 }}
                        aria-label="Skjul meldingen"
                        onClick={() => dismissMessage(entry.id)}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="14">
                            <path
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2.5"
                                d="M12 12l6 6-6-6-6 6 6-6zm0 0L6 6l6 6 6-6-6 6z"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </section>
    );

    return filterDismissedBroadcasts(messages)
        .filter((entry: BroadcastMessage) => !!entry.message)
        .map((entry: BroadcastMessage) => (props.troika ? renderTroika(entry) : renderFabric(entry)));
};
