const BROADCAST_STATE = 'travel_broadcast_state';

const getBroadcastState = () => {
    try {
        return (typeof window !== 'undefined' && JSON.parse(window.localStorage.getItem(BROADCAST_STATE) ?? '[]')) || [];
    } catch (e) {
        return [];
    }
};

export const filterDismissedBroadcasts = (messages) => {
    const dismissed = getBroadcastState().map((entry) => entry.id);

    return messages.filter((message) => !dismissed.includes(message.id));
};

export const dismissBroadcast = (broadcastId) => {
    const currentState = getBroadcastState();

    currentState.push({
        id: broadcastId,
        timeout: Date.now() + 1209600000, // two weeks
    });

    typeof window !== 'undefined' && window.localStorage.setItem(BROADCAST_STATE, JSON.stringify(currentState));
};

export const purgeOutdatedBroadcasts = () =>
    typeof window !== 'undefined' &&
    window.localStorage.setItem(BROADCAST_STATE, JSON.stringify(getBroadcastState().filter((entry) => entry.timeout > Date.now())));
